import React, { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import DeleteWorkerModal from "./deleteWorkerModal";
import "./index.css";
import PrevDetailWeb from "./prevDetailWeb";
import labor from "../../assests/images/labor.jpg";
import { useLocation } from "react-router-dom";

export default function CartPage() {
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectData, setSelectData] = useState({});

  const { cartSelectedItem, userId } = useSelector(
    (state) => state.MANPOWERENQUIRY
  );

  const location = useLocation();
  const skipProfiles = !location?.state?.skipProfiles;
  return (
    <section>
      <div className="container">
        <div className="prev-detail">
          <h3>Preview Details</h3>
          <PrevDetailWeb userId={userId} />
        </div>
        {skipProfiles && (
          <div className="prev-detail selected-workers">
            <div className="row">
              {cartSelectedItem &&
                Object.keys(cartSelectedItem).length > 0 &&
                Object.keys(cartSelectedItem)
                  ?.map(
                    (item) =>
                      cartSelectedItem[item] &&
                      cartSelectedItem[item]?.user?.filter(
                        (filterValue) => filterValue.checked
                      )
                  )
                  .flat()
                  .map((item, index) => (
                    <div className="col-md-4 mt-2">
                      <div className="d-flex justify-content-between align-items-center worker-card-detail">
                        <div className="d-flex worker-name align-items-center">
                          <img src={item?.profile_pic} />
                          {/* <img src={labor} /> */}
                          <div className="ms-3">
                            <p>{item?.fullName}</p>
                            <span>{item?.catageory}</span>
                            <span>
                              {item?.skills[0].SkillName}-
                              {item?.skills[0].SubSkillName}
                            </span>
                            <span style={{ display: "block" }}>
                              Experience - {item?.skills[0].experience}
                            </span>
                          </div>
                        </div>
                        <RiDeleteBin6Line
                          onClick={() => {
                            setDeleteModal(true);
                            setSelectData(item);
                          }}
                          style={{ cursor: "pointer" }}
                          size={24}
                          className="delete-icon"
                        />
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        )}
      </div>
      <DeleteWorkerModal
        open={deleteModal}
        setOpen={setDeleteModal}
        data={selectData}
      />
    </section>
  );
}
