import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import CommonEditor from "../../../components/commonEditor/commonEditor";
import {
  addGulfHomepageService,
  getGulfHomepageService,
} from "../../../services/admin/manageworkforce/manageworkforceservices";
import MaxLimit from "../../../components/MaxLimit";
import { countContentString } from "../../../utils/methods";

function GulfWorking() {
  const [contents, setContents] = useState(null);

  const MAX_LENGTHS = {
    intro_content: 55,
    register_user_content: 53,
    ai_hero_content: 71,
    intro_title: 60,
    register_user_title: 15,
    ai_hero_title: 5,
  };

  useEffect(() => {
    const fetchContents = async () => {
      try {
        const response = await getGulfHomepageService();
        if (response.status) {
          setContents(response.data[0]);
        } else {
          console.error("Failed to fetch CMS contents:", response.message);
        }
      } catch (error) {
        console.error("Error fetching CMS contents:", error);
      }
    };

    fetchContents();
  }, []);

  const initialValues = {
    ai_content: contents ? contents.ai_content : "",
    ai_title: contents ? contents.ai_title : "",
    language_section_content: contents ? contents.language_section_content : "",
    language_section_title: contents ? contents.language_section_title : "",
    intro_title: contents ? contents.intro_title : "",
    intro_content: contents ? contents.intro_content : "",
    address: contents ? contents.address : "",
    email: contents ? contents.email : "",
    phone: contents ? contents.phone : "",
    facebook_link: contents ? contents.facebook_link : "",
    instagram_link: contents ? contents.instagram_link : "",
    register_user_title: contents ? contents.register_user_title : "",
    register_user_content: contents ? contents.register_user_content : "",
    ai_hero_title: contents ? contents.ai_hero_title : "",
    ai_hero_content: contents ? contents.ai_hero_content : "",
  };

  const handleLengthCheck = (value, maxLength, fieldName) => {
    const valueCount = countContentString(value)
    
    if (valueCount >= maxLength) {
      toast.warning(`Maximum length reached for ${fieldName}`);
      return
    }
  };

  const handleSubmit = async (values, { setSubmitting }, section) => {
    try {
      const response = await addGulfHomepageService(
        "66accf0a35fa014192b8f810",
        {...values}
      );

      if (response.status === true) {
        setContents(response.data);
        toast.success(`${section} content updated successfully!`);
      } else {
        toast.error(`Failed to update ${section} content: ${response.message}`);
      }
    } catch (error) {
      console.error(`Error updating ${section} content:`, error);
      toast.error(`Error updating ${section} content: ${error.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <div className="manage_terms_conditions_page card p-4 rounded shadow">
        <PageTile heading1={"Hero Section"} />
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) =>
            handleSubmit(values, { setSubmitting }, "intro")
          }
        >
          {({ errors, touched, values, handleChange, handleBlur }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="intro_title">Title</label>
                <MaxLimit
                  current={values.intro_title.length}
                  max={MAX_LENGTHS.intro_title}
                />
                <Field
                  type="text"
                  id="intro_title"
                  name="intro_title"
                  maxLength={MAX_LENGTHS.intro_title}
                  value={values.intro_title}
                  onChange={(e) => {
                    handleChange(e);
                    handleLengthCheck(e.target.value, MAX_LENGTHS.intro_title, "Intro Title");
                  }}
                  onBlur={handleBlur}
                  className={`form-control ${
                    touched.intro_title && errors.intro_title ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  name="intro_title"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <CommonEditor
                value={values.intro_content}
                onChange={(content) => {
                  handleChange({
                    target: { name: "intro_content", value: content },
                  });
                  handleLengthCheck(content, MAX_LENGTHS.intro_content, "Intro Content");
                }}
                maxLength={MAX_LENGTHS.intro_content}
              />
              <br />

              <div className="form-group">
                <label htmlFor="register_user_title">Registered Users</label>
                <MaxLimit
                  current={values.register_user_title.length}
                  max={MAX_LENGTHS.register_user_title}
                />
                <Field
                  type="text"
                  id="register_user_title"
                  name="register_user_title"
                  value={values.register_user_title}
                  maxLength={MAX_LENGTHS.register_user_title}
                  onChange={(e) => {
                    handleChange(e);
                    handleLengthCheck(e.target.value, MAX_LENGTHS.register_user_title, "Registered Users Title");
                  }}
                  onBlur={handleBlur}
                  className={`form-control ${
                    touched.register_user_title && errors.register_user_title ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  name="register_user_title"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <CommonEditor
                value={values.register_user_content}
                onChange={(content) => {
                  handleChange({
                    target: { name: "register_user_content", value: content },
                  });
                  handleLengthCheck(content, MAX_LENGTHS.register_user_content, "Registered Users Content");
                }}
                maxLength={MAX_LENGTHS.register_user_content}
              />
              <br />

              <div className="form-group">
                <label htmlFor="ai_hero_title">AI</label>
                <MaxLimit
                  current={values.ai_hero_title.length}
                  max={MAX_LENGTHS.ai_hero_title}
                />
                <Field
                  type="text"
                  id="ai_hero_title"
                  name="ai_hero_title"
                  value={values.ai_hero_title}
                  maxLength={MAX_LENGTHS.ai_hero_title}
                  onChange={(e) => {
                    handleChange(e);
                    handleLengthCheck(e.target.value, MAX_LENGTHS.ai_hero_title, "AI Hero Title");
                  }}
                  onBlur={handleBlur}
                  className={`form-control ${
                    touched.ai_hero_title && errors.ai_hero_title ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  name="ai_hero_title"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <CommonEditor
                value={values.ai_hero_content}
                onChange={(content) => {
                  handleChange({
                    target: { name: "ai_hero_content", value: content },
                  });
                  handleLengthCheck(content, MAX_LENGTHS.ai_hero_content, "AI Hero Content");
                }}
                maxLength={MAX_LENGTHS.ai_hero_content}
              />
              <div className="py-3">
                <button type="submit" className="global_admin_btn">
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default GulfWorking;
