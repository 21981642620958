import React, { useState } from "react";
import "./index.css";
import { useSelector } from "react-redux";
import { RiEdit2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { pathUrl } from "../../shared/pathUrl";
import { manPowerEnquirySlice } from "../../redux/manpowerEnquiry/manpowerEnquirySLice";
import { textFormatter } from "../../utils/methods";
import { submitManpowerDataDromthecartPage } from "../../services/web/manpowerEnquiry/manpowerEnquiryservices";
import { toast } from "react-toastify";
import { FaArrowRight } from "react-icons/fa6";
import { Button } from "react-bootstrap";
import ThanksModal from "./thanksModal";
export default function PrevDetailWeb({ userId }) {
  const navigate = useNavigate();
  const dispatch = useNavigate();
  const { cartSelectedItem, formData, categoryTable } = useSelector(
    (state) => state.MANPOWERENQUIRY
  );
  const [thankModal, setThankModal] = useState(false);

  const filtresCategories = formData?.categoryWorkerRequired
    .map((item) => {
      return {
        skillName: item.newSkillName,
        subSkills: item.subSkills.filter((skill) => skill.workers > 0),
      };
    })
    .filter((item) => item.subSkills.length > 0);
  const navigateEdit = (e) => {
    e.preventDefault();
    navigate(pathUrl.homePage, { state: { edit: true, formData: formData } });
  };
  const submitData = async () => {
    // convert the data as per the backend requirement

    const enquiriesData = categoryTable.flatMap((item) =>
      item.subSkills
        .filter((skill) => skill.workers > 0)
        .map((skill) => ({
          skill_name: skill.name,
          sub_id: skill.sub_id,
          workers: skill.workers,
          // years: skill?.days ?? 0,
          months: skill?.days ?? 0,
        }))
    );

    // body to set send to the backend
    const body = {
      email: formData?.email?.value,
      fullName: formData?.name?.value,
      contactNumber: formData?.contactNumber?.value,
      companyName: formData?.companyName?.value,
      accommodation: formData?.accomodation?.value,
      transportation: formData?.transportation?.value,
      message: formData?.message?.value,
      city: formData?.city?.value,
      country: formData?.country?.value,
      user_ids:
        cartSelectedItem &&
        Object.keys(cartSelectedItem)
          .map((item) =>
            cartSelectedItem[item]?.user?.filter(
              (filterValue) => filterValue.checked
            )
          )
          .flat()
          .map((idItem) => idItem?._id),
      enquiries: enquiriesData,
      tenure: formData?.tenure?.value,
      user_type: formData?.userType?.value,
    };
    const postData = await submitManpowerDataDromthecartPage(body, userId);
    if (postData?.status) {
      setThankModal(true);
    } else {
      toast.error("Something went wrong");
    }
  };
  const continueFindWorker = () => {
    dispatch(manPowerEnquirySlice.actions.resetToTheInitialState());
    navigate(pathUrl.homePage);
    setThankModal(false);
  };
  return (
    <div className="prev-detail-card">
      <div className="row mt-4">
        <div className="edit-icon text-end position-relative">
          <RiEdit2Line onClick={(e) => navigateEdit(e)} size={28} />
        </div>

        <div className="col-md-3">
          <div className="prev-card-detail">
            <p>Tenure</p>
            <span>{textFormatter(formData?.tenure?.value)}</span>
          </div>
        </div>
        {/* <div className="col-md-3">
          <div className="prev-card-detail">
            <p>User Type</p>
            <span>{textFormatter(formData?.userType?.value)}</span>
          </div>
        </div> */}
        <div className="col-md-3">
          <div className="prev-card-detail">
            <p>Name</p>
            <span>{formData?.name?.value}</span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="prev-card-detail">
            <p>Email</p>
            <span>{formData?.email?.value}</span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="prev-card-detail">
            <p>Message</p>
            <span>{formData?.message?.value}</span>
          </div>
        </div>
      </div>
      <div className="row mt-3 mb-3">
        <div className="col-md-3">
          <div className="prev-card-detail">
            <p>Company Name</p>
            {formData?.companyName?.value === "" ? (
              <span>No Company Name Provided</span>
            ) : (
              <span>{formData?.companyName?.value}</span>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="prev-card-detail">
            <p>Contact Number</p>
            <span>{formData?.contactNumber?.value}</span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="prev-card-detail">
            <p>Location</p>
            <span>
              {formData?.country?.value},{formData?.city?.value}
            </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="prev-card-detail">
            <p>Accommodation is Taken Care</p>
            <span>{formData?.accomodation?.value}</span>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-3">
          <div className="prev-card-detail">
            <p>Transportation</p>
            <span>{formData?.transportation?.value}</span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="prev-card-detail">
            <p>Categories of Worker Required and Duration</p>
            {filtresCategories.map((item, index) => (
              <div key={index} className="prev-card-detail">
                <span>{item?.skillName}</span>
                <ul>
                  {item.subSkills.map((subItem, subIndex) => (
                    <li key={subIndex}>
                      <span>{`${subItem?.name ?? subItem?.categoryType?.name}(${
                        subItem.workers
                      })`}</span>
                      <span>-</span>
                      <span>{` ${
                        subItem?.months ?? subItem?.days ?? 0
                      } Month`}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="col-md-12 text-end">
        <div className="d-flex gap-5 justify-content-end align-items-center">
          <Button onClick={() => submitData()} className="submit-profile-btn">
            Submit <FaArrowRight />
          </Button>
        </div>
      </div>
      <ThanksModal
        open={thankModal}
        setOpen={setThankModal}
        content={
          "For submit your query. We will contact you as soon as possible."
        }
        continueBtnFun={continueFindWorker}
        btnText={"Continue Find Workers"}
      />
    </div>
  );
}
