import jsPDF from "jspdf";
import "jspdf-autotable";
import { getDownloadedData } from "../services/admin/manageworkforce/manageworkforceservices";

export const capitalizeFirstLetter = (string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const textFormatter = (string) => {
  return capitalizeFirstLetter(string?.replace("_", " "));
};

export const getAllSkills = (data) => {
  return data?.flatMap((item) =>
    item?.subSkills?.flatMap((subSkill) =>
      subSkill?.tertiarySkills?.map((tertiary) => tertiary?.tertiarySkillName)
    )
  );
};

export const getTertiarySkillList = (data) => {
  return data?.map((item) => item.tertiarySkillName);
};

export const downloadPDF = (pdfHeaders, pdfData, fileName = "data.pdf") => {
  const doc = new jsPDF();

  // Define custom styles
  const headerStyles = {
    fillColor: [22, 160, 133], // Example: teal color
    textColor: [255, 255, 255], // White text color
    fontSize: 12,
    fontStyle: "bold",
  };

  const cellStyles = {
    fillColor: [255, 255, 255], // White background for cells
    textColor: [0, 0, 0], // Black text color
    fontSize: 10,
    lineWidth: 0.1,
    lineColor: [200, 200, 200], // Light gray border
  };

  const margin = { top: 20, left: 10, bottom: 10, right: 10 };

  doc.autoTable({
    head: [pdfHeaders], // headers should be a single array within another array
    body: pdfData, // body should be an array of arrays
    margin: margin,
    styles: cellStyles, // Apply styles to all cells
    headStyles: headerStyles, // Apply styles to header cells
    theme: "striped", // Optional: striped rows
    didDrawPage: () => {
      doc.setFontSize(18);
      doc.text("Manpower Enquiry Report", margin.left, 15); // Add title to the top of the page
    },
  });

  doc.save(fileName);
};

export const downloadCSV = (data, fileName = "data.csv") => {
  if (data.length === 0) return; // Guard against empty data

  // Extract headers from the first object
  const headers = Object.keys(data[0]);

  // Generate CSV content
  const csvContent = [
    headers.join(","), // Header row
    ...data.map(
      (row) =>
        headers.map((header) => JSON.stringify(row[header] || "")).join(",") // Data rows
    ),
  ].join("\n");

  const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export function resizeImage(file, maxWidth, maxHeight) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = () => {
      img.src = reader.result;
    };

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set canvas dimensions to the desired size
      canvas.width = maxWidth;
      canvas.height = maxHeight;

      // Draw the image on the canvas, stretching/compressing to fit the exact size
      ctx.drawImage(img, 0, 0, maxWidth, maxHeight);

      // Convert the canvas to a Blob and then to a File
      canvas.toBlob((blob) => {
        if (blob) {
          const resizedFile = new File([blob], file.name, {
            type: file.type,
            lastModified: Date.now(),
          });
          resolve(resizedFile);
        } else {
          reject(new Error("Error converting canvas to blob"));
        }
      }, file.type);
    };

    img.onerror = reject;
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function getTextLength(htmlString) {
  const tagRegex = /<\/p><p>|<br\s*\/?>|<\/?p>/g;

  const matches = htmlString.match(tagRegex) || [];
  const tagCount = matches.length;

  const cleanedHtmlString = htmlString.replace(tagRegex, "");

  const textContent =
    new DOMParser().parseFromString(cleanedHtmlString, "text/html").body
      .textContent || "";

  return textContent.length;
}

export const getQueryParams = (params) => {
  const query = Object.entries(params)
    .filter(([_, value]) => value !== undefined) // Filter out undefined values
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
  return query ? `?${query}` : "";
};
export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

// downloaded pdf csv file
/**
 *
 * @param {string} type which section you have to download
 * @param {string} format in which format you have to download this like pdf or csv
 * @param {string} nameOfFile which name you have to set for the file name
 */
export const downloadCsvPdfFile = async (type, format, nameOfFile) => {
  try {
    const getData = await getDownloadedData(type, format);
    console.log("-----getData", getData);
    const fileName = `${nameOfFile}.${format}`;
    if (getData?.downloadLink) {
      const link = document.createElement("a");
      link.href = getData?.downloadLink; // Use the URL directly
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Download link is not available.");
    }
  } catch (error) {
    console.log("err", error);
  }
};



export function countContentString(htmlString) {
  const strippedString = htmlString.replace(/<[^>]*>/g, '');
  return strippedString.trim().length;
}