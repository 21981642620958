import React, { useEffect, useRef, useState, useCallback } from "react";
import { Button } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import TableComponent from "../../components/categoryTable/categoryTable";
import WorkerCard from "./workerCard";
import { getTheWorkerAsPerCategory } from "../../services/web/manpowerEnquiry/manpowerEnquiryservices";
import { manPowerEnquirySlice } from "../../redux/manpowerEnquiry/manpowerEnquirySLice";
import notfound from "../../assests/images/not-found.png";
import "./index.css";
import { pathUrl } from "../../shared/pathUrl";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";

export default function WorkerListing() {
  const dispatch = useDispatch();
  const [tableShow, setTableShow] = useState(false);
  const [maxCount, setMaxCount] = useState(0);
  const [selectedCartItems, setSelectedCartItems] = useState(0);
  const { cartSelectedItem, categoryTable, formData } = useSelector(
    (state) => state.MANPOWERENQUIRY
  );
  const cartData = { ...cartSelectedItem };

  const navigate = useNavigate();

  Object.keys(cartData).forEach((key) => {
    const filteredSkills = formData.categoryWorkerRequired.filter(
      (item) => item.newSkillName === cartData[key].skillName
    );

    const reqWorkers = filteredSkills?.[0]?.subSkills?.filter(
      (item) => item.workers > 0
    )?.[0]?.workers;

    cartData[key] = {
      ...cartData[key],
      RequiredWorkers: reqWorkers,
    };
  });

  const [filterValue, setFilterValue] = useState(
    cartData && Object.keys(cartData)[0]
  );
  const tableRef = useRef(null);

  const onFilterByCategory = (data, workers) => {
    setFilterValue(data);
    setMaxCount(workers);
  };

  const getTheWorkerAsPerTheBackend = useCallback(async () => {
    const body = {
      tertiarySkill: categoryTable
        ?.flatMap((item) => item?.subSkills || [])
        ?.filter((filterItem) => filterItem.workers > 0)
        .map((filterItem) => filterItem?.categoryType?.id),
    };
    const postData = await getTheWorkerAsPerCategory(body);
    if (postData?.data?.data?.length === 0) {
      return alert("data not found");
    }
    const values = postData?.data?.data;
    let newObj = { ...cartData };
    if (values) {
      Object.keys(values).forEach((elItem) => {
        if (!cartData[elItem]) {
          newObj[elItem] = values[elItem];
        }
      });
    }

    if (cartData) {
      Object.keys(cartData).forEach((elItem) => {
        if (!values[elItem]) {
          delete newObj[elItem];
        }
      });
    }

    dispatch(manPowerEnquirySlice.actions.selectCartItem(newObj));
  }, [cartData, categoryTable, dispatch]);

  const cartitemsLength = Object.keys(cartData).length;

  useEffect(() => {
    setMaxCount(cartData?.[filterValue]?.RequiredWorkers ?? 0);
  }, [filterValue, formData]);
  useEffect(() => {
    setSelectedCartItems(
      cartData[filterValue]?.user?.filter((filterValue) => filterValue.checked)
        ?.length ?? 0
    );
  }, [cartData, formData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tableRef.current && !tableRef.current.contains(event.target)) {
        setTableShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!tableShow) {
      getTheWorkerAsPerTheBackend();
    }
  }, [tableShow]);

  return (
    <>
      <section>
        <div className="container">
          <Button
            onClick={() =>
              navigate(pathUrl.cart, {
                state: {
                  skipProfiles: true,
                },
              })
            }
            className="skip-profile-btn"
          >
            Proceed without profile selection <FaArrowRight />
          </Button>
          <div className="row worker-list">
            <div className="col-md-8">
              <div className="d-flex flex-wrap">
                {cartData &&
                  Object.keys(cartData).map((item, index) => (
                    <div
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        onFilterByCategory(
                          item,
                          cartData[item]?.RequiredWorkers
                        )
                      }
                      className={`d-flex justify-content-between align-items-center worker-badage ${
                        filterValue === item ? "" : ""
                      }`}
                    >
                      {cartData[item]?.skillName}- {item}
                      <span>{cartData[item]?.RequiredWorkers}</span>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex justify-content-end selected_workers">
                <div className="worker-selected">
                  Workers selected:
                  <span>
                    {selectedCartItems}/{maxCount}
                  </span>
                </div>
                <div ref={tableRef} className="position-relative">
                  <Button
                    value={tableShow}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setTableShow((prev) => !prev);
                    }}
                    className="worker-badage-category"
                  >
                    Add More Categories
                    <MdKeyboardArrowDown onClick={(e) => e.stopPropagation()} />
                  </Button>
                  {tableShow && (
                    <div className="categories_drop_menu">
                      <TableComponent
                        theads={[
                          "Category",
                          "Tertiary Skills",
                          "Workers",
                          "Months",
                        ]}
                        selectedCartItems={selectedCartItems}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="d_flex_mulit_img">
            {cartData &&
            cartitemsLength > 0 &&
            cartData[filterValue]?.user?.length > 0 ? (
              cartData[filterValue]?.user?.map((item, index) => (
                <WorkerCard
                  key={index}
                  selectedSkill={filterValue}
                  index={index}
                  cardData={item}
                  selectedCartItems={selectedCartItems}
                  maxCount={maxCount}
                />
              ))
            ) : (
              <section className="w-100">
                <div className="container">
                  <div className="not-found-profile text-center">
                    <img className="img-fluid" src={notfound} />
                    <h2 className="mt-5">
                      We couldn't find the exact profile match, but don't worry
                      sit back and relax.
                    </h2>
                    <p>
                      Our team has received your inquiry and we will arrange the
                      right candidate for you.
                    </p>
                  </div>
                </div>
              </section>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
